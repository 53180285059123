import classNames from 'classnames';

import { Image as IImage } from '@features/pg-funnel/services/contentful/types';

import styles from './Image.module.scss';
import { forwardRef } from 'react';

export interface IImageProps {
  image: IImage;
  hasLazyLoading?: boolean;
  isClickable?: boolean;
  className?: string;
  type?: string;
  sizes?: string;
}

const DEFAULT_SIZES =
  '(max-width: 375px) 375px, (max-width: 768px) 768px, (max-width: 768px) 768px, (max-width: 1024px) 1024px, 1440px';

/**
 * Deprecated: Use ContentfulImage instead
 * https://olympusinvestment.atlassian.net/jira/software/c/projects/SC/boards/28?quickFilter=135&selectedIssue=SC-3958
 */
export const Image = forwardRef<HTMLImageElement, IImageProps>(
  (
    {
      image,
      hasLazyLoading = false,
      isClickable,
      className,
      type,
      sizes = DEFAULT_SIZES,
    },
    ref,
  ) => (
    <picture>
      {image.srcSetWebp ? (
        <source srcSet={image.srcSetWebp} type="image/webp" sizes={sizes} />
      ) : null}
      <source srcSet={image.srcSet} type={type || 'image/png'} sizes={sizes} />
      <img
        ref={ref}
        src={image.src}
        alt={image.alt}
        sizes={sizes}
        loading={hasLazyLoading ? 'lazy' : 'eager'}
        className={classNames({ [styles.isClickable]: isClickable }, className)}
      />
    </picture>
  ),
);

Image.displayName = 'Image';
