const getSlug = (link) => {
  if (link.fields.slug && link.sys.contentType.sys.id === 'funnel') {
    return `funnel/${link.fields.slug}`
  }

  if (
    link.fields.internalLink?.fields.slug &&
    link.fields.internalLink?.sys.contentType.sys.id === 'funnel'
  ) {
    return `funnel/${link.fields.internalLink.fields.slug}`
  }

  if (link.fields.slug) {
    return `/${link.fields.slug}`
  }
  return `/${link.fields?.internalLink?.fields?.slug}`
}

/**
 * @deprecated use the new link mapper: features/shared/contentful/utils/mapLink.ts
 */
export default function mapLink (link) {
  return (link && link.fields && {
    slug: getSlug(link),
    externalLink: link.fields.externalLink && {
      link: link.fields.externalLink,
      openInNewTab: link.fields.openExternalLinkInNewTab
    }
  }) || {}
}
