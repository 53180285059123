import { mapPageLanguages } from './map-languages';
import mapContentBlocks from './map-content-blocks';
import { mapPageHeader } from './map-page-header';
import mapTemporaryOffer from './map-temporary-offer';
import { IContentPage } from 'src/features/shared/contentful/types/IContentPage';
import { IPageHeader } from 'src/features/shared/contentful/types/IPageHeader';
import { ITemporaryOffer } from 'src/features/shared/contentful/types/ITemporaryOffer';
import { getPageTags } from 'src/utils/getPageTags';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapPage(data: any, status?: string): IContentPage {
  return {
    id: data.sys.id,
    slug: data.fields.slug,
    metaTitle: data.fields.metaTitle,
    metaDescription: data.fields.metaDescription,
    languages: data.languages && mapPageLanguages(data.languages),
    type: data.sys.contentType.sys.id,
    title: data.fields.title,
    pageHeader: mapPageHeader(data.fields.pageHeader) as IPageHeader,
    temporaryOffer: mapTemporaryOffer(
      data.fields.temporaryOffer,
    ) as ITemporaryOffer,
    contentBlocks: mapContentBlocks(data.fields.contentBlocks),
    isPaymentSuccess: status === 'success',
    showConfetti: data.fields.showConfetti,
    noIndex: data.fields.noIndex,
    pageType: data.fields.pageType,
    contentfulTags: getPageTags(data.metadata.tags),
    contentTableTitle: data.fields.contentTableTitle,
  };
}
