import React from 'react';
import { Link } from '@features/shared/components/link';
import styles from './NavigationListSecondary.module.scss';
import { IMenuItem } from '../../contentful/types/IMenuItem';

interface NavigationListSecondaryProps {
  items?: IMenuItem[];
}

export function NavigationListSecondary({
  items = [],
}: NavigationListSecondaryProps) {
  return (
    <ul>
      {items.map((item) => {
        const href = item.link?.href;

        if (!href) {
          return null;
        }

        return (
          <li key={item.link?.label} className={styles.item}>
            <Link
              href={href}
              isHtmlTag={href.startsWith('/funnel')}
              className={styles.link}
            >
              {item.link?.label}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
