import { useState } from 'react'

export type IUseSheetReturn = {
  isOpen: boolean
  isOpening: boolean
  isClosing: boolean
  open: () => void
  close: () => void
}

export function useSheet () {
  const [ isOpen, setOpen ] = useState(false)
  const [ isClosing, setClosing ] = useState(false)
  const [ isOpening, setOpening ] = useState(false)

  const close = () => {
    setOpening(false)
    setClosing(true)
    setTimeout(() => {
      setOpen(false)
      setClosing(false)
    }, 300)
  }

  const open = () => {
    setClosing(false)
    setOpening(true)
    setTimeout(() => {
      setOpen(true)
      setOpening(false)
    }, 300)
  }

  const toggle = () => {
    if (isOpen) {
      close()
    } else {
      open()
    }
  }

  return {
    isOpen,
    isOpening,
    isClosing,
    open,
    close,
    toggle
  }
}
