import { IPageHeaderSection } from '@features/pg-funnel/services/contentful/types/generated/contentful'
import { mapUsps } from '@features/pg-funnel/services/contentful/utils/map-usps'
import { mapLink } from '@features/shared/contentful/mappers/mapLink'
import { DEFAULT_IMAGE_SIZES } from '@features/shared/constants/defaultImageSizes'
import { getResponsiveImageSizes } from '@features/shared/contentful/utils/getResponsiveImageSizes'

import mapImage from './map-image'

export function mapPageHeader (data: IPageHeaderSection) {
  if (!data) return

  const sizes = [ 375, 768, 1440, 1920 ]
  return {
    id: data.sys.id,
    type: data.sys.contentType.sys.id,
    title: data.fields.title,
    subtitle: data.fields.subtitle,
    cta: data.fields.cta && mapLink(data.fields.cta, data.fields.ctaLabel),
    ctaLabel: data.fields.ctaLabel,
    description: data.fields.description,
    imageStyle: data.fields.imageStyle,
    image: mapImage(data.fields.image, sizes, 100),
    mobileImage: mapImage(data.fields.mobileImage, sizes, 100),
    mainImageSizes: getResponsiveImageSizes(sizes),
    collageImages: data.fields.collageImages?.map((asset) => mapImage(asset, DEFAULT_IMAGE_SIZES, 100)),
    contentAlignment: data.fields.contentAlignment,
    hasClubSearch: data.fields.hasClubSearch,
    hasFontColorLight: data.fields.hasFontColorLight,
    usps: mapUsps(data.fields.usps?.map(item => item.fields))
  }
}
