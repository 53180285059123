import mapImage from 'services/contentful/utils/map-image';
import { ICardItem as ICardItemCF } from 'src/features/pg-funnel/services/contentful/types/generated/contentful';
import { mapLink } from 'src/features/shared/contentful/mappers/mapLink';
import { ICardItem } from 'src/features/shared/contentful/types/ICardItem';
import { ILink } from 'src/features/shared/contentful/types/ILink';

export function mapCardItem(data: ICardItemCF[]): ICardItem[] {
  return data?.map((item) => ({
    id: item.sys.id,
    title: item.fields.title,
    link: mapLink(item.fields.link, item.fields.title) as ILink,
    image: mapImage(item.fields?.image, [700, 570, 366, 486]),
  }));
}
