export const GA4_EVENTS = {
  trackEvent: 'track_event',
  pageData: 'page_data',
  completeCheckout: 'complete_checkout',
  viewSearchResults: 'view_search_results',
  clickCode: 'click_code',
  clickMembership: 'click_membership',
  clickPersonalInfo: 'click_personalinfo',
  clickStartVerification: 'click_startverification',
  clickExtras: 'click_extras',
  clickFaqItem: 'click_faq_item',
  productClick: 'product_click',
  addToCart: 'add_to_cart',
  checkout: 'checkout',
  checkoutOption: 'checkout_option',
  submitFunnel: 'submit_funnel',
  funnelLoad: 'funnel_load',
  beginCheckout: 'begin_checkout',
  addShippingInfo: 'add_shipping_info',
  addPaymentInfo: 'add_payment_info',
  purchase: 'purchase',
};
