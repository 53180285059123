import mapImage from './map-image';
import mapLink from './map-link';

export const mapContentNode = (node) => {
  const isEntryHyperlink =
    node.nodeType === 'entry-hyperlink' && node.data?.target;

  if (isEntryHyperlink) {
    return {
      ...node,
      data: {
        ...node.data,
        target: {
          fields: {
            title: node.data.target.fields.title,
            slug: node.data.target.fields.slug,
          },
        },
      },
    };
  }

  if (node.content?.length > 0) {
    return {
      ...node,
      content: node.content.map(mapContentNode),
    };
  }

  return node;
};

export const mapRichText = (field) => {
  const isRichText = field?.nodeType === 'document';
  if (isRichText) {
    return {
      ...field,
      content: field?.content.map((node) => {
        return {
          ...node,
          content: node.content.map(mapContentNode),
        };
      }),
    };
  }

  return field;
};

export default function mapSection(data) {
  return {
    title: data.title,
    hasTitleDecoration: data.hasTitleDecoration,
    image: data.image ? mapImage(data.image) : null,
    description: data.description ? mapRichText(data.description) : null,
    label: data.linkLabel || null,
    link: data.link ? mapLink(data.link) : null,
    externalLink: data.externalLink || null,
    displayAs: data.displayAs || null,
    linkImage: data.linkImage || null,
    backgroundColor: data.backgroundColor || null,
    textPosition: data.textPosition || null,
    secondaryLink:
      data.secondaryLink && data.secondaryLink.fields
        ? mapLink(data.secondaryLink, data.secondaryLinkLabel || '')
        : undefined,
    secondaryLinkDisplayAs: data.secondaryLinkDisplayAs,
    secondaryLinkLabel: data.secondaryLinkLabel,
  };
}
