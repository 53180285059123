import classNames from 'classnames';
import { ReactNode, useMemo } from 'react';
import {
  SECTIONS_BG_COLORS,
  SectionBackgroundColor,
} from 'src/features/shared/utils/constants';
import styles from './index.module.scss';

function useLayoutClass(src = '', prefix: string) {
  return useMemo(() => {
    if (!src) {
      return undefined;
    }

    return src
      .split(' ')
      .map((col) =>
        prefix
          ? styles[`${prefix}-${col.split(':').join('-')}`]
          : styles[col.split(':').join('-')],
      );
  }, [src, prefix]);
}

function useRowClass(value: boolean | string | undefined, type: string) {
  return useMemo(() => {
    if (!value) {
      return undefined;
    }
    if (typeof value === 'boolean') {
      return styles[type];
    }
    return value.split(' ').map((screenSize) => {
      return styles[`${type}-${screenSize}`];
    });
  }, [value, type]);
}

interface ContainerProps {
  children: ReactNode;
  className?: string;
  forceMobileView?: boolean;
  noPadding?: boolean;
  backgroundColor?: SectionBackgroundColor | null;
  stickToBottom?: boolean;
  stickToTop?: boolean;
}

export function Container({
  children,
  forceMobileView = false,
  noPadding = false,
  className: givenClassName,
  backgroundColor,
  stickToBottom,
  stickToTop,
}: ContainerProps) {
  const className = classNames(
    styles.container,
    forceMobileView && styles.forceMobileView,
    noPadding && styles.noPadding,
    givenClassName,
  );

  const innerClassName = classNames({
    [styles.hasBgColor]: !!backgroundColor,
    [styles.stickToBottom]: stickToBottom,
    [styles.stickToTop]: stickToTop,
    [styles.softPeach]: backgroundColor === SECTIONS_BG_COLORS.softPeach,
    [styles.softSand]: backgroundColor === SECTIONS_BG_COLORS.softSand,
    [styles.softYellow]: backgroundColor === SECTIONS_BG_COLORS.softYellow,
  });

  const renderChildren = () => {
    if (backgroundColor) {
      return (
        <Row>
          <Col>
            <div className={innerClassName}>{children}</div>
          </Col>
        </Row>
      );
    }

    return children;
  };

  return <div className={className}>{renderChildren()}</div>;
}

interface RowProps extends ContainerProps {
  component?: 'ul' | 'li' | 'div';
  hasNoVerticalReset?: boolean;
  isVerticallyCentered?: boolean;
  reversed?: boolean;
  noGap?: boolean;
  backgroundColor?: SectionBackgroundColor | null;
  stickToBottom?: boolean;
  stickToTop?: boolean;
}

export function Row({
  component: Component = 'div',
  children,
  className: givenClassName,
  hasNoVerticalReset,
  isVerticallyCentered,
  reversed,
  noGap = false,
  backgroundColor,
  stickToBottom,
  stickToTop,
  ...props
}: RowProps) {
  const reversedStyle = useRowClass(reversed, 'reversed');
  const className = classNames(styles.row, givenClassName, reversedStyle, {
    [styles['has-no-vertical-reset']]: hasNoVerticalReset,
    [styles['vertically-center']]: isVerticallyCentered,
    [styles['no-gap']]: noGap,
    [styles.hasBgColor]: !!backgroundColor,
    [styles.stickToBottom]: stickToBottom,
    [styles.stickToTop]: stickToTop,
    [styles.softPeach]: backgroundColor === SECTIONS_BG_COLORS.softPeach,
    [styles.softSand]: backgroundColor === SECTIONS_BG_COLORS.softSand,
    [styles.softYellow]: backgroundColor === SECTIONS_BG_COLORS.softYellow,
  });
  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  );
}

interface ColProps {
  component?: 'li' | 'div';
  children: ReactNode;
  className?: string;
  columns?: string;
  offset?: string;
  hasCenteredContent?: boolean;
  noPadding?: boolean;
  backgroundColor?: SectionBackgroundColor | null;
  stickToBottom?: boolean;
  stickToTop?: boolean;
}

export function Col({
  component: Component = 'div',
  children,
  className: givenClassName,
  columns,
  offset,
  hasCenteredContent,
  noPadding,
  backgroundColor,
  stickToBottom,
  stickToTop,
}: ColProps) {
  const columnStyle = useLayoutClass(columns, '');
  const offsetStyle = useLayoutClass(offset, 'offset');
  const className = classNames([
    styles.column,
    givenClassName,
    columnStyle,
    offsetStyle,
    {
      [styles.centered]: hasCenteredContent,
      [styles.noPadding]: noPadding,
      [styles.hasBgColor]: !!backgroundColor,
      [styles.softPeach]: backgroundColor === SECTIONS_BG_COLORS.softPeach,
      [styles.softSand]: backgroundColor === SECTIONS_BG_COLORS.softSand,
      [styles.softYellow]: backgroundColor === SECTIONS_BG_COLORS.softYellow,
      [styles.stickToBottom]: stickToBottom,
      [styles.stickToTop]: stickToTop,
    },
  ]);

  return <Component className={className}>{children}</Component>;
}

export { Section } from './section';

export const Layout = {
  Container,
  Row,
  Col,
};
