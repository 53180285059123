import mapImage from 'services/contentful/utils/map-image'
import mapLink from 'services/contentful/utils/map-link'

export default function mapImageCarouselItems (items) {
  if (!items) return []
  return items.map(item => {
    return {
      id: item.sys.id,
      image: item.fields.image ? {
        ...mapImage(item.fields.image, [ 309, 289, 381, 476 ]),
        title: item.fields.image.fields.title,
        description: item.fields.image.fields.description
      } : null,
      link: item.fields.link && mapLink(item.fields.link),
      label: item.fields.buttonLabel || item.fields.label,
      hasButton: item.fields.hasButton,
      title: item.fields.title,
      description: item.fields.description
    }
  })
}
