import { isCamelCase } from './check'

export function stringToCamelCase (string: string): string {
  if (isCamelCase(string)) {
    return string
  }

  return string
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase())
}
