import mapLink from 'services/contentful/utils/map-link'

export default function mapVideoSection (data) {
  if (!data) return null
  return {
    title: data.title,
    videoId: data.videoId,
    description: data.description,
    link: mapLink(data.link),
    linkLabel: data.linkLabel
  }
}
