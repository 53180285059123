import React from 'react';
import classNames from 'classnames';
import { Link } from '@features/shared/components/link';
import { LoginLink } from 'src/features/shared/components/login-link';
import theme from 'config/theme';
import styles from './NavigationList.module.scss';
import { IMenuItem } from '../../contentful/types/IMenuItem';

interface INavigationListProps {
  items?: IMenuItem[];
  isHorizontal?: boolean;
}

export function NavigationList({
  items = [],
  isHorizontal,
}: INavigationListProps) {
  return (
    <ul
      className={classNames(styles.list, { [styles.horizontal]: isHorizontal })}
    >
      {theme.login && (
        <li className={classNames([styles.item, styles.login])}>
          <LoginLink />
        </li>
      )}
      {items &&
        items.map((item) => (
          <li key={item.link?.label} className={styles.item}>
            <Link href={item.link?.href || `/${item.link?.slug}`}>
              {item.link?.label}
            </Link>
          </li>
        ))}
    </ul>
  );
}
